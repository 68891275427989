import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import { makeStyles, TextField, Button, Link } from '@material-ui/core';
import NavBar from './NavBar';
import TopBar from './TopBar';
import { useAuth0, withAuthenticationRequired } from '@auth0/auth0-react';
import { GET_USER, ADD_USER } from 'src/GraphQLData';
import { useQuery, useMutation } from '@apollo/client';
import LoadingScreen from 'src/components/LoadingScreen';
import { create, sum } from 'lodash';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

const institutions = ['LoyolaChicago', 'UChicago'];

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    display: 'flex',
    height: '100%',
    overflow: 'hidden',
    width: '100%'
  },
  wrapper: {
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden',
    paddingTop: 64,
    [theme.breakpoints.up('lg')]: {
      paddingLeft: 150
    }
  },
  contentContainer: {
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden'
  },
  content: {
    flex: '1 1 auto',
    height: '100%',
    overflow: 'auto'
  }
}));

const DashboardLayout = ({ children }) => {
  const [open, setOpen] = React.useState(false);
  const [created, setCreated] = React.useState(false);
  const [institution, setInstitution] = React.useState('LoyolaChicago');
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleContinue = () => {
    setCreated(true);
  };

  const handleChange = (event) => {
    setInstitution(event.target.value);
  };

  const classes = useStyles();
  const [isMobileNavOpen, setMobileNavOpen] = useState(false);

  const [addUser] = useMutation(ADD_USER, {
    onCompleted(data) {
      handleClose();
    }
  });

  const { user } = useAuth0();

  const { loading, error, data } = useQuery(GET_USER, {
    variables: { username: user.email }
  });

  useEffect(() => {
    if (data) {
      if (data.getUser === null && created === false) {
        setOpen(true);
      } else if (data.getUser === null && created === true) {
        const newUser = {
          username: user.email,
          name: user.nickname,
          institution: institution
        };
        addUser({
          variables: {
            user: newUser
          }
        });
      }
    }
  }, [data, created]);

  if (loading) return <LoadingScreen />;
  if (error) return `Error! ${error}`;

  return (
    <div className={classes.root}>
      <TopBar onMobileNavOpen={() => setMobileNavOpen(true)} />
      <NavBar
        onMobileClose={() => setMobileNavOpen(false)}
        openMobile={isMobileNavOpen}
      />
      <div className={classes.wrapper}>
        <div className={classes.contentContainer}>
          <div className={classes.content}>{children}</div>
        </div>
      </div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">
          Specify Username and Institution
        </DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            id="litter"
            value={institution}
            type="number"
            select
            onChange={handleChange}
            SelectProps={{ native: true }}
            variant="outlined"
            fullWidth
          >
            {institutions.map((x) => (
              <option key={x} value={x}>
                {x}
              </option>
            ))}
          </TextField>
        </DialogContent>
        <DialogActions>
          <div>
            <Button onClick={handleContinue} color="secondary">
              Continue
            </Button>
          </div>
        </DialogActions>
      </Dialog>
    </div>
  );
};

DashboardLayout.propTypes = {
  children: PropTypes.any
};

export default withAuthenticationRequired(DashboardLayout, {
  onRedirecting: () => <LoadingScreen />
});
