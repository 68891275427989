import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import 'react-perfect-scrollbar/dist/css/styles.css';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import 'react-quill/dist/quill.snow.css';
import 'nprogress/nprogress.css';
import 'src/assets/css/prism.css';
import 'src/mixins/chartjs';
import 'src/mixins/prismjs';
import { enableES5 } from 'immer';
import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';
import * as serviceWorker from 'src/serviceWorker';
import { SettingsProvider } from 'src/context/SettingsContext';
import { restoreSettings } from 'src/utils/settings';
import App from 'src/App';

import { Auth0Provider } from "@auth0/auth0-react";
import config from "src/auth_config.json";
import history from "./utils/history";

const onRedirectCallback = (appState) => {
  history.push(
    appState && appState.returnTo
      ? appState.returnTo
      : window.location.pathname
  );
};


enableES5();

const settings = restoreSettings();

ReactDOM.render(
  <Auth0Provider
        domain={config.domain}
        clientId={config.clientId}
        redirectUri={window.location.origin}
        onRedirectCallback={onRedirectCallback}
        >
      <SettingsProvider settings={settings}>
        
          <App />
      </SettingsProvider>
  </Auth0Provider>
  ,
  document.getElementById('root')
);

serviceWorker.unregister();
