/* eslint-disable react/no-array-index-key */
import React, { lazy, Suspense, Fragment } from 'react';
import { Switch, Redirect, Route } from 'react-router-dom';
import DashboardLayout from 'src/layouts/DashboardLayout';
import MainLayout from 'src/layouts/MainLayout';
import HomeView from 'src/views/pages/HomeView';
import LoadingScreen from 'src/components/LoadingScreen';

const routesConfig = [
  {
    exact: true,
    path: '/',
    component: () => <Redirect to="/home" />
  },
  {
    exact: true,
    path: '/404',
    component: lazy(() => import('src/views/pages/Error404View'))
  },
  {
    path: '/app',
    layout: DashboardLayout,
    routes: [
      {
        exact: true,
        path: '/app',
        component: () => <Redirect to="/app/reports/Dashboard" />
      },
      {
        exact: true,
        path: '/app/reports/dashboard',
        component: lazy(() => import('src/views/reports/Dashboard'))
      },
      {
        exact: true,
        path: '/app/reports',
        component: () => <Redirect to="/app/reports/Dashboard" />
      },
      {
        exact: true,
        path: '/app/management/colonies',
        component: lazy(() => import('src/views/management/ColonyListView'))
      },
      {
        exact: true,
        path: '/app/management/colonies/create',
        component: lazy(() => import('src/views/management/ColonyCreateView'))
      },
      {
        exact: true,
        path: '/app/management/colonies/:colonyId/editcolony',
        component: lazy(() => import('src/views/management/ColonyCreateView'))
      },
      {
        exact: true,
        path: '/app/management/colonies/:colonyId',
        component: lazy(() => import('src/views/management/AnimalListView'))
      },
      {
        exact: true,
        path: '/app/management/colonies/:colonyId/createanimal',
        component: lazy(() => import('src/views/management/AnimalCreateView'))
      },
      {
        exact: true,
        path: '/app/management/colonies/:colonyId/createmating',
        component: lazy(() => import('src/views/management/MatingCreateView'))
      },
      {
        exact: true,
        path: '/app/management/colonies/:colonyId/matingsearch',
        component: lazy(() => import('src/views/management/MatingSearchView'))
      },
      {
        exact: true,
        path: '/app/management/colonies/:colonyId/:animalId/editanimal',
        component: lazy(() => import('src/views/management/AnimalCreateView'))
      },
      {
        exact: true,
        path: '/app/management/colonies/:colonyId/:matingId/editmating',
        component: lazy(() => import('src/views/management/MatingCreateView'))
      },
      {
        exact: true,
        path: '/app/management/colonies/:colonyId/createlitter',
        component: lazy(() => import('src/views/management/LitterCreateView'))
      },
      {
        exact: true,
        path: '/app/management',
        component: () => <Redirect to="/app/management/colonies" />
      },
      {
        exact: true,
        path: '/app/calendar',
        component: lazy(() => import('src/views/calendar/CalendarView'))
      },
      {
        exact: true,
        path: '/app/extra/charts/apex',
        component: lazy(() => import('src/views/extra/charts/ApexChartsView'))
      },
      {
        exact: true,
        path: '/app/extra/forms/formik',
        component: lazy(() => import('src/views/extra/forms/FormikView'))
      },
      {
        exact: true,
        path: '/app/extra/forms/redux',
        component: lazy(() => import('src/views/extra/forms/ReduxFormView'))
      },
      {
        exact: true,
        path: '/app/extra/editors/draft-js',
        component: lazy(() => import('src/views/extra/editors/DraftEditorView'))
      },
      {
        exact: true,
        path: '/app/extra/editors/quill',
        component: lazy(() => import('src/views/extra/editors/QuillEditorView'))
      },
      {
        component: () => <Redirect to="/404" />
      }
    ]
  },
  {
    path: '*',
    layout: MainLayout,
    routes: [
      {
        exact: true,
        path: '/home',
        component: HomeView
      },
      {
        exact: true,
        path: '/pricing',
        component: lazy(() => import('src/views/pages/PricingView'))
      },
      {
        component: () => <Redirect to="/404" />
      }
    ]
  }
];

const renderRoutes = routes =>
  routes ? (
    <Suspense fallback={<LoadingScreen />}>
      <Switch>
        {routes.map((route, i) => {
          const Guard = route.guard || Fragment;
          const Layout = route.layout || Fragment;
          const Component = route.component;

          return (
            <Route
              key={i}
              path={route.path}
              exact={route.exact}
              render={props => (
                <Guard>
                  <Layout>
                    {route.routes ? (
                      renderRoutes(route.routes)
                    ) : (
                      <Component {...props} />
                    )}
                  </Layout>
                </Guard>
              )}
            />
          );
        })}
      </Switch>
    </Suspense>
  ) : null;

function Routes() {
  return renderRoutes(routesConfig);
}

export default Routes;
