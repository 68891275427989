import React, { useEffect, useState } from 'react';
import { Router } from 'react-router-dom';
import { create } from 'jss';
import MomentUtils from '@date-io/moment';
import { SnackbarProvider } from 'notistack';
import {
  createStyles,
  jssPreset,
  makeStyles,
  StylesProvider,
  ThemeProvider
} from '@material-ui/core';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import CookiesNotification from 'src/components/CookiesNotification';
import SettingsNotification from 'src/components/SettingsNotification';
import GoogleAnalytics from 'src/components/GoogleAnalytics';
import ScrollReset from 'src/components/ScrollReset';
import useSettings from 'src/hooks/useSettings';
import { createTheme } from 'src/theme';
import Routes from 'src/Routes';

import { ApolloProvider, ApolloClient, InMemoryCache, gql } from '@apollo/client';
import { setContext } from 'apollo-link-context';
import { createHttpLink } from 'apollo-link-http';
import LoadingScreen from 'src/components/LoadingScreen';

import { useAuth0 } from '@auth0/auth0-react';
import history from './utils/history';

const jss = create({ plugins: [...jssPreset().plugins] });

const useStyles = makeStyles(() => createStyles({
  '@global': {
    '*': {
      boxSizing: 'border-box',
      margin: 0,
      padding: 0,
    },
    html: {
      '-webkit-font-smoothing': 'antialiased',
      '-moz-osx-font-smoothing': 'grayscale',
      height: '100%',
      width: '100%'
    },
    body: {
      height: '100%',
      width: '100%'
    },
    '#root': {
      height: '100%',
      width: '100%'
    }
  }
}));

const dgraphcloudURL = 'https://relieved-start.us-west-2.aws.cloud.dgraph.io/graphql';
const gqlURL = 'https://dgraph.colonycore.app/graphql'

const createApolloClient = (token) => {
  const httpLink = createHttpLink({
    uri: dgraphcloudURL,
    options: {
      reconnect: true,
    },
  });

  const authLink = setContext((request, { headers }) =>
    // return the header to the context so httpLink can read them
    ({
      headers: {
        ...headers,
        'X-Auth-Token': token,
      },
    }));

  return new ApolloClient({
    link: authLink.concat(httpLink),
    cache: new InMemoryCache()
  });
};

const App = () => {
  useStyles();
  const {
    isLoading, error, getIdTokenClaims
  } = useAuth0();
  const [idToken, setIdToken] = useState('');
  const { settings } = useSettings();

  useEffect(() => {
    const initAuth0 = async () => {
      if (!isLoading) {
        try {
          const idTokenClaims = await getIdTokenClaims();
          setIdToken(idTokenClaims.__raw);
        } catch (e) {
          console.log(e.message);
        }
      }
    };
    initAuth0();
    // eslint-disable-next-line
  }, [isLoading]);

  if (isLoading) {
    return (
      <ThemeProvider theme={createTheme(settings)}>
        <LoadingScreen />
      </ThemeProvider>
    );
  }

  if (error) {
    return (
      <div>
        Oops...
        {error.message}
      </div>
    );
  }

  const client = createApolloClient(idToken);

  return (
    <ApolloProvider client={client}>
      <ThemeProvider theme={createTheme(settings)}>
        <StylesProvider jss={jss}>
          <MuiPickersUtilsProvider utils={MomentUtils}>
            <SnackbarProvider maxSnack={1}>
              <Router history={history}>
                <ScrollReset />
                <GoogleAnalytics />
                {/* <CookiesNotification /> */}
                {/* <SettingsNotification /> */}
                <Routes />
              </Router>
            </SnackbarProvider>
          </MuiPickersUtilsProvider>
        </StylesProvider>
      </ThemeProvider>
    </ApolloProvider>
  );
};

export default App;
