import { gql } from '@apollo/client';

/*
 ** User Data
 */

export const GET_USER = gql`
  query getUser($username: String!) {
    getUser(username: $username) {
      username
      name
      colonies {
        name
      }
    }
  }
`;

export const ADD_USER = gql`
  mutation addUser($user: AddUserInput!) {
    addUser(input: [$user]) {
      user {
        username
      }
    }
  }
`;

/*
 ** Colony Data
 */
export const GET_COLONIES = gql`
  query {
    queryColony {
      id
      active
      name
      species
      genes
      coats
      identifiers
      notes
      animals {
        id
        name
        dob
        sex
        alive
      }
      matings {
        id
      }
      user {
        name
      }
    }
  }
`;

export const GET_COLONIES_DASHBOARD = gql`
  query {
    queryColony(filter: {active: true}) {
      id
      name
      species
      animals(filter: {alive: true}) {
        id
        name
      }
      matings {
        id
        status
        timed_pregnant
      }
      user {
        name
      }
    }
  }
`;

export const GET_MATCHING_ANIMALS = gql`
  query MyQuery($alleles: Alleles, $name: String) {
    queryGene(
      filter: { alleles: { eq: $alleles }, and: { name: { eq: $name } } }
    ) {
      name
      alleles
      animal {
        id
        name
        dob
      }
    }
  }
`;

export const GET_COLONY_ANIMALS_MATINGS = gql`
  query getColony($id: [ID!]) {
    queryColony(filter: { id: $id }) {
      id
      active
      name
      species
      genes
      coats
      identifiers
      animals {
        id
        name
        dob
        sex
        alive
        notes
        coat
        identifier
        mother {
          id
          name
          sex
        }
        father {
          id
          name
          sex
        }
        genotype {
          id
          name
          alleles
        }
      }
      matings {
        date
        f_weight
        id
        sp_date
        timed_pregnant
        status
        notes
        mother {
          alive
          id
          name
          sex
        }
        father {
          alive
          id
          name
          sex
        }
      }
    }
  }
`;

export const GET_COLONY_MALE_ANIMALS = gql`
  query getColony($id: [ID!]) {
    queryColony(filter: { id: $id }) {
      id
      animals(filter: { sex: { eq: MALE } }) {
        id
        name
      }
    }
  }
`;

export const GET_COLONY_FEMALE_ANIMALS = gql`
  query getColony($id: [ID!]) {
    queryColony(filter: { id: $id }) {
      id
      animals(filter: { sex: { eq: FEMALE } }) {
        id
        name
      }
    }
  }
`;

export const GET_CALENDAR = gql`
  query {
    queryCalendarEvent {
      description
      end
      id
      start
      title
    }
  }
`;

export const ADD_COLONY = gql`
  mutation addColony($colony: [AddColonyInput!]!) {
    addColony(input: $colony) {
      numUids
    }
  }
`;

export const ADD_CALENDAR_EVENT = gql`
  mutation addCalendarEvent($calendarevent: [AddCalendarEventInput!]!) {
    addCalendarEvent(input: $calendarevent) {
      numUids
    }
  }
`;

export const UPDATE_COLONY = gql`
  mutation updateColony($id: [ID!], $set: ColonyPatch) {
    updateColony(input: { filter: { id: $id }, set: $set }) {
      numUids
    }
  }
`;

export const REMOVE_GENES_FROM_COLONY = gql`
  mutation removeGenes($id: [ID!], $genes: [String], $coats: [String], $identifiers: [String]) {
    updateColony(input: { filter: { id: $id }, remove: {coats: $coats, genes: $genes, identifiers: $identifiers} }) {
      numUids
    }
  }
`;

export const DEL_COLONY = gql`
  mutation deleteColony($id: [ID!]) {
    deleteColony(filter: { id: $id }) {
      colony {
        id
      }
    }
  }
`;

export const DEL_CALENDAR_EVENT = gql`
  mutation deleteCalendarEvent($id: [ID!]) {
    deleteCalendarEvent(filter: { id: $id }) {
      calendarEvent {
        id
      }
    }
  }
`;

export const ADD_ANIMAL = gql`
  mutation addAnimal($animal: [AddAnimalInput!]!) {
    addAnimal(input: $animal) {
      animal {
        id
      }
    }
  }
`;

export const UPDATE_ANIMAL = gql`
  mutation updateAnimal($id: [ID!], $set: AnimalPatch) {
    updateAnimal(input: { filter: { id: $id }, set: $set }) {
      numUids
    }
  }
`;

export const UPDATE_GENE = gql`
  mutation updateGene($id: [ID!], $set: GenePatch) {
    updateGene(input: { filter: { id: $id }, set: $set }) {
      numUids
    }
  }
`;

export const REMOVE_FATHER = gql`
  mutation removeFather($id: [ID!]) {
    updateAnimal(input: { filter: { id: $id }, set: { father: null } })
  }
`;

export const REMOVE_MOTHER = gql`
  mutation removeMother($id: [ID!]) {
    updateAnimal(input: { filter: { id: $id }, set: { mother: null } })
  }
`;

export const DEL_ANIMAL = gql`
  mutation deleteAnimal($id: [ID!]) {
    deleteAnimal(filter: { id: $id }) {
      animal {
        id
      }
    }
  }
`;

export const DEL_GENE = gql`
  mutation deleteGene($id: [ID!]) {
    deleteGene(filter: { id: $id }) {
      gene {
        id
      }
    }
  }
`;

export const ADD_MATING = gql`
  mutation addMating($mating: [AddMatingInput!]!) {
    addMating(input: $mating) {
      mating {
        id
      }
    }
  }
`;

export const UPDATE_MATING = gql`
  mutation updateMating($id: [ID!], $set: MatingPatch) {
    updateMating(input: { filter: { id: $id }, set: $set }) {
      numUids
    }
  }
`;

export const DEL_MATING = gql`
  mutation deleteMating($id: [ID!]) {
    deleteMating(filter: { id: $id }) {
      mating {
        id
      }
    }
  }
`;
