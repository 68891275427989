import React from 'react';

function Logo(props) {
  return (
    <img
      alt="Logo"
      src="/static/cc2.svg"
      height="50"
      {...props}
    />
  );
}

export default Logo;
